<template>
  <div>
    <h1 class='text-2xl mb-16'>
      {{reportName}}
    </h1>
    <div class='mb-12'>
      <a class='hover:bg-gray-100 rounded-md py-2 px-8 border' :href='announcementLink' target='_blank'>Go To Dart Page</a>
      <button class='hover:bg-gray-100 rounded-md ml-4 py-2 px-8 border' @click='createInvestmentCard'>Card생성</button>
    </div>
    <div class='mb-12'>
      <label class='form-label'>종목</label>
      <div>{{drilldownCorporateAnnouncement.name}} ({{drilldownCorporateAnnouncement.share_code}})</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>권리유형</label>
      <div>{{drilldownCorporateAnnouncement.action}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>증자방식</label>
      <div>{{drilldownCorporateAnnouncement.details['증자방식']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>신주의 종류와 수</label>
      <div>총액: {{ numberStyle(totalQuantity) }}</div>
      <div>보통주: {{ numberStyle(drilldownCorporateAnnouncement.details['보통주']) }}</div>
      <div>{{quantityTitle}}: {{ numberStyle(priortyQuantity) }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>자금조달의 목적</label>
      <div>총액: {{ numberStyle(totalAmount) }}</div>
      <div>시설자금: {{ numberStyle(drilldownCorporateAnnouncement.details['시설자금']) }}</div>
      <div>영업양수자금: {{ numberStyle(drilldownCorporateAnnouncement.details['영업양수자금'] )}}</div>
      <div>운영자금: {{ numberStyle(drilldownCorporateAnnouncement.details['운영자금']) }}</div>
      <div>채무상환자금: {{ numberStyle(drilldownCorporateAnnouncement.details['채무상환자금']) }}</div>
      <div>타법인증권취득자금: {{ numberStyle(drilldownCorporateAnnouncement.details['타법인증권취득자금']) }}</div>
      <div>기타자금: {{ numberStyle(drilldownCorporateAnnouncement.details['기타자금']) }}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>납입일</label>
      <div>{{drilldownCorporateAnnouncement.details['납입일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>신주배정기준일</label>
      <div>{{drilldownCorporateAnnouncement.details['신주배정기준일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>상장예정일</label>
      <div>{{drilldownCorporateAnnouncement.details['상장예정일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>할인율또는할증율</label>
      <div>{{drilldownCorporateAnnouncement.details['할인율또는할증율']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>배당기산일</label>
      <div>{{drilldownCorporateAnnouncement.details['배당기산일']}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Date</label>
      <div>{{drilldownCorporateAnnouncement.receipt_date}}</div>
    </div>
    <div class='mb-12'>
      <label class='form-label'>Receipt Number</label>
      <div>{{drilldownCorporateAnnouncement.receipt_number}}</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CorporateAnnouncementStockDetails',
  components: {
  },
  data () {
    return {
      // showConfirmDelete: false,
    }
  },
  computed: {
    reportName () {
      return this.drilldownCorporateAnnouncement.report_name
    },
    ...mapState('corporateAnnouncements', [
      'drilldownCorporateAnnouncement',
    ]),
    ...mapState('entities', [
      'entities'
    ]),
    announcementLink () {
      return this.drilldownCorporateAnnouncement.url ? this.drilldownCorporateAnnouncement.url : ''
    },
    entity () {
      return this.entities.find(entity => entity.entity_name === this.drilldownCorporateAnnouncement.name)
    },
    priortyQuantity () {
      return this.drilldownCorporateAnnouncement.details['우선주'] === undefined ? this.drilldownCorporateAnnouncement.details['기타주'] : this.drilldownCorporateAnnouncement.details['우선주']
    },
    totalQuantity () {
      return Number(this.drilldownCorporateAnnouncement.details['보통주']) + Number(this.priortyQuantity)
    },
    totalAmount () {
      return Number(this.drilldownCorporateAnnouncement.details['시설자금']) + Number(this.drilldownCorporateAnnouncement.details['영업양수자금']) + Number(this.drilldownCorporateAnnouncement.details['운영자금']) +
            Number(this.drilldownCorporateAnnouncement.details['채무상환자금']) + Number(this.drilldownCorporateAnnouncement.details['타법인증권취득자금']) + Number(this.drilldownCorporateAnnouncement.details['기타자금'])
    },
    quantityTitle () {
      return this.drilldownCorporateAnnouncement.details['우선주'] === undefined ? '기타주' : '우선주'
    }
  },
  methods: {
    ...mapActions('entities', [
      'createEntityWithName',
      'getEntities',
    ]),
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investments', [
      'createInvestment',
    ]),
    createInvestmentCard () {
      // TODO: get smarter and dont recreate cards even on announcement updates
      // and smarter entity finding
      let investment = {}
      if (this.entity === undefined) {
        this.createEntityWithName(this.drilldownCorporateAnnouncement.name).then(resp => {
          investment.investment_name = resp.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
          investment.entity_id = resp.id
          this.createInvestment(investment)
        })
      } else {
        investment.investment_name = this.entity.entity_name + ' - ' + this.drilldownCorporateAnnouncement.asset_class
        investment.entity_id = this.entity.id
        this.createInvestment(investment)
      }
    },
    doneEditing () {
      this.sidepanelClose()
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
  mounted () {
    this.getEntities()
  }
}
</script>

<style lang='scss' scoped>

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
